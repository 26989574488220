export default {
            Auth: {
                region: 'us-east-1',
                userPoolId: 'us-east-1_a4YzXydqq',
                userPoolWebClientId: '5bdl5nq7bnthk1qhtt0ttddaui',
                oauth: {
                    domain: 'smart-app-broker-console-us1-stage.auth.us-east-1.amazoncognito.com',
                    scope: ['email', 'profile', 'openid'],
                    redirectSignIn: 'https://smart-app-broker.stage.bd-mms.com/console',
                    redirectSignOut: 'https://smart-app-broker.stage.bd-mms.com/console',
                    responseType: 'code',
                    options: {
                        AdvancedSecurityDataCollectionFlag: false
                    }
                }
            }
        }